@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"), url(./Assets/fonts/Helvetica.ttf) format("truetype");
}

@font-face {
  font-family: "Helvetica-Bold";
  src: local("Helvetica"),
    url(./Assets/fonts/Helvetica-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "HelveticaNeue";
  src: local("HelveticaNeue"),
    url(./Assets/fonts/HelveticaNeue.ttf) format("truetype");
}

@font-face {
  font-family: "HelveticaNeue-Bold";
  src: local("HelveticaNeue"),
    url(./Assets/fonts/HelveticaNeue-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "HelveticaNeue-Medium";
  src: local("HelveticaNeue"),
    url(./Assets/fonts/HelveticaNeue-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Inter-Regular";
  src: local("Inter-Regular"),
    url(./Assets/fonts/Inter-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Inter-Regular_Bold";
  src: local("Inter-Regular"),
    url(./Assets/fonts/Inter-Regular_Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Inter-Regular_Light";
  src: local("Inter-Regular"),
    url(./Assets/fonts/Inter-Regular_Light.ttf) format("truetype");
}

@font-face {
  font-family: "Inter-Regular_Medium";
  src: local("Inter-Regular"),
    url(./Assets/fonts/Inter-Regular_Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Inter-Regular_SemiBold";
  src: local("Inter-Regular"),
    url(./Assets/fonts/Inter-Regular_SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Moderat-Bold";
  src: local("Moderat"), url(./Assets/fonts/Moderat-Bold.otf) format("opentype");
}

@font-face {
  font-family: "Moderat-Medium";
  src: local("Moderat"),
    url(./Assets/fonts/Moderat-Medium.otf) format("opentype");
}

@font-face {
  font-family: "Moderat-Regular";
  src: local("Moderat"),
    url(./Assets/fonts/Moderat-Regular.otf) format("opentype");
}

.react-multi-carousel-track li {
  width: 15.31rem !important;
  margin-right: 25px;
  margin-left: 5px;
}

@media (max-width: 500px) {
  .react-multi-carousel-track li {
    width: 9.3rem !important;
    margin-right: 1rem;
    margin-left: 0;
  }
}

.react-multi-carousel-track li:last-child {
  width: 15.31rem !important;
  margin-right: 25rem;
  margin-left: 5px;
}

.react-datepicker-wrapper {
  width: 100%;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

@media (max-width: 767px) {
  input {
    font-size: 16px !important;
  }
}

/* Date
Picker
 CSS */
.react-datepicker {
  font-size: 1em !important;
}

.react-datepicker__header {
  padding-top: 0.8em !important;
}

.react-datepicker__month {
  margin: 0.4em 1em !important;
}

.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.9em !important;
  line-height: 1.9em !important;
  margin: 0.166em !important;
}

.react-datepicker__current-month {
  font-size: 1em !important;
}

.react-datepicker__navigation {
  top: 1em !important;
  line-height: 1.7em !important;
  border: 0.45em solid transparent !important;
}

.react-datepicker__navigation--previous {
  border-right-color: #ccc !important;
  left: 1em !important;
}

.react-datepicker__navigation--next {
  border-left-color: #ccc !important;
  right: 1em !important;
}

.step-container-body {
  height: 100%;
  box-sizing: border-box;
  padding-right: 13px;
  width: 100%;
}

.step-container-body-add-more-services {
  height: 100%;
  box-sizing: border-box;
  padding-right: 13px;
  width: 100%;
  padding-left: 100px;
}

.step-container-body-move-again {
  height: 100%;
  box-sizing: border-box;
  padding-right: 13px;
  width: 100%;
  padding-left: 77px;
  padding-right: 80px;
}

@media (max-width: 800px) {
  .step-container-body-add-more-services {
    padding-left: 40px;
  }

  .step-container-body-move-again {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 1410px) {
  .step-container-body {
    padding: 0 30px;
  }
}

@media (max-width: 959px) {
  .step-container-body {
    height: calc(calc(var(--vh, 1dvh) * 100) - 165px);
  }
}

@media (max-width: 767px) {
  .step-container-body {
    height: calc(calc(var(--vh, 1dvh) * 100) - 145px);
  }
}

.rotate-screen-icon {
  width: 150px;
  height: 150px;
  margin-bottom: 40px;
}

.rotate-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  font-size: 20px;
}
