html {
  font-size: 100%;
  background-color: #fefefe;
}
@media (max-width: 1500px) {
  html {
    font-size: 80%;
  }
}
@media (max-width: 1400px) {
  html {
    font-size: 75%;
  }
}

@media (max-width: 800px) {
  html,
  body {
    overscroll-behavior: none;
  }
}
