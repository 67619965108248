.menu-header {
  height: 89px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  z-index: 11;
}

.menu-inner {
  width: 100%;
  max-width: 1170px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 27px;
}

.left,
.right {
  display: flex;
}

.right {
  align-items: center;
  display: flex;
}

.reallymoving-logo {
  width: 240px;
  padding-top: 10px;
  margin-left: 35px;
  margin-bottom: 10px;
}

.reallymoving-logo-mobile {
  margin-left: 20px;
  width: 120px;
  height: 40px;
}

.chapperone-logo-mobile {
  width: 120px;
  margin: 0 30px;
}

.chapperone-logo-wrapper {
  align-items: center;
  justify-content: center;
  display: flex;
}

.menu-list {
  list-style: none;
  display: flex;
  gap: 11px;
  margin: 0;
  padding: 0;
}

.menu-list li {
  font-family: "roboto", "Helvetica Neue";
  font-size: 14px;
  font-weight: 500;
}

.menu-list li a {
  padding: 10px 8px;
  text-decoration: none;
  color: #666;
  transition: color 0.3s ease;
}

.menu-list li a:hover {
  text-decoration: underline;
}

.menu-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 44px;
  color: #452765;
  background-color: white;
  border: 2px solid #452765;
  margin-left: 20px;
  padding: 12px 5px;
  border-radius: 5px;
  font-family: "roboto", "Helvetica Neue";
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.menu-button:hover {
  background-color: #eaeaea;
}

.st-navbar-icon {
  width: 12.125rem;
  cursor: pointer;
}

@media (max-width: 767px) {
  .st-navbar-icon {
    width: 12.125rem;
    cursor: pointer;
  }

  .menu-header {
    display: none;
  }

  .reallymoving-logo-mobile {
    margin-left: -10px;
    width: 91px;
    height: 24px;
  }

  .chapperone-logo-mobile {
    width: 120px;
    height: 24px;
  }
}

.container-styles {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
