.FAQ {
  margin-top: 9.25rem;
}
.FAQHeading {
  font-size: 3.125rem;
  font-family: "Helvetica";
  font-weight: 700;
  margin-bottom: 2.875rem;
  color: #050505;
  letter-spacing: -0.14px;
}
.FAQItems {
  width: 69%;
  margin: auto;
  /* position: relative; */
}
.FAQItem {
  height: 3.4375rem;
  width: 100%;
  margin-top: 1.875rem;

  overflow: hidden;
  cursor: pointer;
  position: relative;
  z-index: 5;
  border: 1px solid #000000;
  font-size: 1.9rem;
  font-family: "Helvetica";
  font-weight: 400;
  color: #000000;
  letter-spacing: 0px;
}

.FAQItemImage {
  position: absolute;
  right: 1.5rem;
}
.FAQItemImageActive {
  position: absolute;
  right: 1.5rem;
  transform: rotate(90deg);
}
@keyframes answer {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}
.FAQItemAnswer {
  top: 3.4375rem;
  z-index: 0;
  left: 0;
  width: 100%;
  padding: 0.4rem;
  font-size: 1.625rem;
  font-family: "Helvetica";
  /* border-left: 1px solid #000000;
   border-right: 1px solid #000000;
   border-bottom: 1px solid #000000; */
  /* animation: answer 0.3s; */
}

.FAQBox {
  padding-top: 1.5rem;
  padding-bottom: 2.875rem;
  border: 3px solid #0f1543;
  border-radius: 10px;
  width: 33vw;
  margin: 0 auto;
  margin-top: 4rem;
}

.mnSetup4MainHeading {
  font-size: 1.625rem;
  font-family: "Helvetica";
  font-weight: 700;
  color: #39494f;
  margin-right: 2rem;
}
.FAQBoxHeading {
  font-size: 3rem;
  font-family: "Helvetica";
  font-weight: 700;
  line-height: 1.2;
  color: #000000;
  letter-spacing: 0.5px;
  text-align: center;
}
.FAQBoxParaContainer {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.FAQBoxPara {
  font-size: 1.5rem;
  font-weight: 400;
  font-family: "Helvetica";
  color: #39494f;
  text-align: center;
  line-height: 1.2;
  margin: 0;
  margin: 0.3rem 0;
}
.FAQBoxBtn {
  height: 3.8rem;
  width: 13.75rem;
  background-color: #3a4cea;
  border-radius: 2.2rem;
  font-size: 1.5rem !important;
  color: #ffffff !important;
  letter-spacing: -0.11px;
  font-weight: 500;
  font-family: "Helvetica";
}
@media (max-width: 1200px) {
  .FAQItems {
    width: 90%;
  }
  .FAQImg {
    height: 1.5rem;
    width: 1.5rem;
  }
}
@media (max-width: 767px) {
  .FAQ {
    width: 90vw;
    margin: 0 auto;
    margin-top: 5rem;
  }
  .FAQHeading {
    font-size: 2rem;
  }
  .mnSetup4MainHeading {
    font-size: 1.25rem;
    margin-right: 0.5rem !important;
  }
  .FAQItems {
    width: 100% !important;
    margin: auto;
    position: relative;
  }
  .FAQItem {
    height: auto;
    font-size: 1.3rem;
    line-height: 1.3;
    padding-right: 3rem;
  }
  .FAQItemAnswer {
    font-size: 1.3rem;
  }

  .FAQBox {
    width: 75vw;
  }
  .FAQBoxHeading {
    font-size: 2.5rem;
  }
  .FAQBoxParaContainer {
    margin-top: 2rem;
    margin-bottom: 2.5rem;
  }
  .FAQBoxPara {
    font-size: 1.3rem;
  }
  .FAQBoxBtn {
    font-size: 1.3rem !important;
  }
}
